<template>
  <!-- 个人账号 -->
  <div class="box-card" style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="个人账号：">
        <el-input v-model="queryForm.phone" maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入个人账号"
          @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="身份证：">
        <el-input v-model="queryForm.card_num" @keyup.enter.native="onSubmit" placeholder="请输入身份证"
          @input="(e) => (queryForm.card_num = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="昵称：">
        <el-input v-model="queryForm.nick_name" maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入昵称"
          @input="(e) => (queryForm.nick_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <el-form-item label="手机端：">
            <el-select popper-class="my-select" v-model="queryForm.user_type" filterable default-first-option placeholder="请选择账号来源" @change="onSubmit"  clearable>
              <el-option v-for="item in getOptions('XZUserType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="个人账号">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('635b4f5eb10b00004400719c')">删除
          </lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="register_pic">
            <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]" v-if="row.register_pic">
            </el-image>
            <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
              :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else></el-image>
          </template>
          <template slot-scope="{row}" slot="organizations">
            <el-link type="primary" style="margin-right: 10px" v-if="row.organizations.length > 0"
              @click="openPersonDialog(row.organizations)">{{ row.organizations.length }}
            </el-link>
            <span v-else>{{ row.organizations.length }}</span>
          </template>
          <template slot-scope="{row}" slot="last_logintime">
            {{ validDateTime(row.last_logintime) }}
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;" border stripe  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column label="头像" width="100">
            <template slot-scope="scope">
              <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]"
                v-if="scope.row.register_pic">
              </el-image>
              <el-image fit="contain" :src="require('@/assets/img/noMessage/noPeopleInLight.png')"
                :preview-src-list="[require('@/assets/img/noMessage/noPeopleInLight.png')]" v-else></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="nick_name" label="昵称" min-width="100"></el-table-column>
          <el-table-column prop="phone" label="个人账号"></el-table-column>
          <el-table-column prop="card_num" label="身份证" min-width="100"></el-table-column>
          <el-table-column label="关联组织" width="150">
            <template slot-scope="scope">
              <el-link type="primary" style="margin-right: 10px" v-if="scope.row.organizations.length > 0"
                @click="openPersonDialog(scope.row.organizations)">{{ scope.row.organizations.length }}
              </el-link>
              <span v-else>{{ scope.row.organizations.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最近登录时间" width="150">
            <template slot-scope="scope">
              {{ validDateTime(scope.row.last_logintime) }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="150">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="手机端" width="130">
            <template slot-scope="scope">
              <span>{{ getWayName("XZUserType", scope.row.user_type) }} </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination popper-class="my-select" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>

    <!-- <OrganizationalStructure @closewindow="isVisible = false" v-if="isVisible" :id="Id" /> -->
    <lebo-dialog title="关联组织" :isShow="isPersonDialog" width="30%" @close="isPersonDialog = false" footerSlot>
      <el-table :data="personTable" border stripe class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column property="organization_name" label="组织"></el-table-column>
      </el-table>
    </lebo-dialog>
  </div>
</template>
<script>
import request from '@/api/systemapi'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
  },
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        nick_name: '',
        phone: '',
        card_num: '',
        user_type: null // 手机端
      },
      total: 0,
      tableData: [],
      multipleSelection: [],
      isPersonDialog: false, // 是否展示组织成员对话框
      personTable: [], // 组织成员列表
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // selection: true,
        // selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '头像',
            prop: 'register_pic',
            slot: true
          }, {
            label: '昵称',
            prop: 'nick_name'
          }, {
            label: '个人账号',
            prop: 'phone'
          }, {
            label: '身份证',
            prop: 'card_num'
          }, {
            label: '关联组织',
            prop: 'organizations',
            slot: true
          }, {
            label: '最近登录时间',
            prop: 'last_logintime',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time'
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.onSubmit()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    // 获取组织列表
    async fnGetPersonalAccountList () {
      const res = await request.getPersonalAccountList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        nick_name: this.queryForm.nick_name,
        phone: this.queryForm.phone,
        card_num: this.queryForm.card_num,
        user_type: this.queryForm.user_type ? this.queryForm.user_type : 0 // 手机端
      })
      if (res && res.Code === 200 && res.Data) {
        console.log('巡检人员信息----', res.Data.DataList)
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      // console.log(this.queryForm);
      this.queryForm.PageIndex = 1
      this.fnGetPersonalAccountList()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetPersonalAccountList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPersonalAccountList()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetPersonalAccountList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPersonalAccountList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      //  // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (val) {
    //   this.multipleSelection = val
    // },
    // // 删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('是否确认删除此账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.deleteOrg(idArr)
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的账号')
      }
    },
    // 删除组织请求
    async deleteOrg (obj) {
      const res = await request.deletePersonalAccount(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetPersonalAccountList()
    },
    // 查看关联组织
    openPersonDialog (organizations) {
      this.isPersonDialog = true
      var arr = []
      organizations.forEach(item => arr.push({ organization_name: item }))
      this.personTable = arr
    },
    configDialogClose () {
      this.$refs.configFormRef.resetFields()
      // console.log(this.$refs.configFormRef.resetField());
    }
  }
}
</script>
<style scoped lang="less">
.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 550px;
    overflow-y: auto;
  }
}

.el-image {
  width: 55px;
  height: 55px;

  /deep/.el-image__error {
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size: 0;
  }
}

.content_box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .demo-ruleForm {
    width: 60%;
  }

  .qr_content {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 100%;
    }
  }
}
</style>
